<template>
    <div class="detail">
        <iframe :src="detailUil" frameborder="0"  scrolling="no" id="iframe_one"></iframe>
    </div>
</template>
<style lang="scss" scoped>
*{
    padding: 0;
    margin: 0;
}
li{
    list-style: none;
}
#iframe_one{
    width: 100%;
  overflow: hidden;
  height: 100%;
  margin-bottom: 20px;
}
.detail{
    width: 100%;
}

</style>
<script>
export default {
    data() {
    return {
      detailUil: "",
      
      classid:'',
      completeLoad: "",
    };
  },
  created() {
      var leng = this.$route.path;
      var lth = leng.lastIndexOf('h')
      this.classid =leng.substring(8,(lth-1))
      this.detailUil='https://huixinic.class.gaoxiaobang.com/huixin/'+this.classid+'/huixinDetail'

  },
  mounted(){
    var _this = this;
      const oIframe1 = document.getElementById("iframe_one");
      const divv=document.querySelector('.detail')
      if(!_this.completeLoad){
        divv.style.height = 1000 + "px";

   }else{
     divv.style.height = 100+'%'
   }
    if (oIframe1.attachEvent) {
      oIframe1.attachEvent("onload", function () {
        _this.completeLoad = true;
      });
    } else {
      oIframe1.onload = function () {
        _this.completeLoad = true;
      };
    }
      window.addEventListener("message", function (e) {
        if (e.data < 1000) {
        oIframe1.style.height = 1000 + "px";
      } else {
        oIframe1.style.height = e.data + "px";
      }
        console.log(e);
    });
   
  }
    
}
</script>